import React from "react"
import CookieUkPolicy from "../../../components/body/pages/en-gb/legal/cookie/index"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

const CookieUkPage = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/en-gb/legal/cookie-policy/"}
      title="Cookie Policy | Kuda | The Money App for Africans"
    />
    <CookieUkPolicy />
  </Layout>
)

export default CookieUkPage
