import { Link } from "gatsby"
import React, { useEffect, Fragment } from "react"
import { scrollToElement } from "../../../../../utility/utils"
import CTAUk from "../../../general/ctaUk"

const CookieUkPolicy = () => {
    useEffect(() => {
        scrollToElement()
        window.addEventListener("scroll", function () {
            scrollToElement()
        })
    }, [])

    return (
        <Fragment>
            <div className="kuda-inner--heading kuda-disclaimer--section">
                <div className="kuda-section--inner general-style">
                    <div className="kuda-section--100 inner-spotlight--heading pad-bottom text-center">
                        <Link to="/en-gb/legal/" className="color-black mb-3 mt-4 back-page">
                            <div className="align-self-center mr-2">
                                <div className="f-14">
                                    Legal
                                </div>
                            </div>
                            <span className="mr-2">
                                <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M0.0631554 1.92842L1.48887 0.5L7.99558 6.99436L1.48952 13.5L0.0625 12.0729L5.14004 6.99567L0.0631554 1.92842Z"
                                        fill="#C7C7CC"
                                    ></path>
                                </svg>
                            </span>
                            <span className="align-self-center f-14 mr-2 text-left">United Kingdom</span>
                            <span className="mr-2">
                                <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M0.0631554 1.92842L1.48887 0.5L7.99558 6.99436L1.48952 13.5L0.0625 12.0729L5.14004 6.99567L0.0631554 1.92842Z"
                                        fill="#C7C7CC"
                                    ></path>
                                </svg>
                            </span>
                            <span className="align-self-center f-14 text-bold text-left">Cookie policy</span>
                        </Link>
                        <div className="legal-left">
                            <h1 className="kuda-section--heading text-lg-biz mb-0 text-xlbold color-primary title-bottom--spacing no-margins">
                                Cookie policy
                            </h1>
                        </div>
                    </div>

                    <div className="kuda-section--100 kuda-spacing--inner">
                        <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
                            1. Introduction
                        </h1>
                        <div className="text-left kuda-disclaimer--wrap">
                            <p className="color-black bottom--spacing">
                                Kuda UK uses cookies for a variety of purposes, including ensuring that our website functions as expected, remembering your preferences and gathering data on the users of our website, App and our services.
                            </p>
                            <p className="color-black bottom--spacing">
                                By using our website (through any device) you agree that this Cookie Policy, together with our Privacy Policy (Link on website) and Website Terms of Conditions (Link on website), applies to that use.
                            </p>
                            <p className="color-black bottom--spacing">
                                We are committed to protecting you and any information (anonymous or otherwise) that we collect about you online. This section tells you about how and why we use cookies, and how this allows us to improve our service.
                            </p>
                            <p className="color-black bottom--spacing">
                                If you wish to use our website, but would like us not to set cookies in your browser, you can disable or remove the cookies. Please note that disabling or removing the cookies we or our third party service providers set may impact the functionality and security of our website and our ability to provide our services to you. Please note that you will not be able to use our website or the associated services if you do not accept our use of cookies.
                            </p>
                            <p className="color-black bottom--spacing">
                                We reserve the right to make changes to our Cookie Policy. Any changes we may make to our Cookie Policy in the future will be posted on this page and, where appropriate, notified to you by email. Please check back frequently to see any updates or changes to our Cookie Policy. Your continued use of our website is taken as your agreement to any such changes.
                            </p>
                        </div>
                    </div>

                    <div className="kuda-section--100 kuda-spacing--inner">
                        <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
                            2. About cookies
                        </h1>
                        <div className="text-left kuda-disclaimer--wrap">
                            <p className="color-black bottom--spacing">
                                Cookies are files containing small amounts of information which are downloaded to the device you use when you visit a website. Your web browser (such as Internet Explorer, Mozilla Firefox, Google Chrome or Safari) then sends these cookies back to our website on each subsequent visit. Cookies do not contain any information that personally identifies you.
                            </p>
                            <p className="color-black bottom--spacing">
                                We and our reputable third party service providers may use the information we obtain about you through the use of cookies for the following purposes:
                            </p>
                            <p className="color-black bottom--spacing">
                                <ul className="diclaimer-list">
                                    <li>To recognise your device(s) when you visit our website;</li>
                                    <li>To remember if you’ve set any preferences on our website;</li>
                                    <li>To temporarily store data as part of, for example, customer surveys;</li>
                                    <li>To test new content and evaluate its effectiveness;</li>
                                    <li>To remember if you’ve been informed of our Cookies Policy, Privacy Policy and other Terms;</li>
                                    <li>To track you as you navigate our website (including tracking page usage and paths used by visitors through our website; and tracking use of our Internet banner advertisements and other links from our marketing partners’ websites to our website;</li>
                                    <li>To improve our website’s usability;</li>
                                    <li>To allow you to share pages with social networks such as LinkedIn, Facebook and Twitter / to support social media components, like Facebook or Twitter (where our website uses a plugin from these third party platforms);</li>
                                    <li>To ensure you don’t miss information that is relevant to you (including targeting our website content, targeting our marketing campaigns and direct marketing emails, targeting our Internet banner advertisements on our website and on other websites);</li>
                                    <li>To analyse use of our website (including for statistical analysis, sales and marketing research).</li>
                                </ul>
                            </p>
                            <p className="color-black bottom--spacing">
                                To stop us setting these cookies you will need to set up your browser to reject all cookies (see further below).
                            </p>
                            <p className="color-black bottom--spacing">
                                We may also combine this web usage data with other information we have collected about you. We store this information so that we have a better and more specific understanding of the way users are using our website, and their preferences and interests, for the purposes set out above.
                            </p>
                            <p className="color-black bottom--spacing">
                                Cookies may be either “persistent” cookies or “session” cookies, depending on how long they are used.
                            </p>
                            <p className="color-black bottom--spacing">
                                Persistent cookies remain on your device after you have closed your browser, and allow a website to remember your actions and preferences. They are activated each time you visit the website where the cookie was generated. Sometimes persistent cookies are used by websites to provide targeted advertising based on the browsing history of the device. They are stored by the browser and remain valid until their set expiry date (unless deleted by the user before the expiry date).
                            </p>
                            <p className="color-black bottom--spacing">
                                Session cookies only last for the duration of your visit and are deleted when you close your browser. They facilitate tasks such as allowing a website to identify that a user of a particular device is navigating from page to page, supporting website security or basic functionality.
                            </p>
                            <p className="color-black bottom--spacing">
                                Many of the cookies we use are session cookies. For example, they help us to ensure the security of your session, and can also keep you signed in to your Kuda UK account while you move between pages.
                            </p>


                            <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
                                3.  More about the cookies we use
                            </h1>
                            <div className="text-left kuda-disclaimer--wrap">
                                <p className="color-black bottom--spacing">
                                    Whether a cookie is a first or third party cookie depends on which website the cookie comes from. First party cookies are those set by or on behalf of the website visited. All other cookies are third party cookies. We use both first party and third party cookies.
                                </p>
                                <p className="color-black bottom--spacing">
                                    In some cases, some of these cookies are managed for us by third parties, but we don’t allow the third party to use the cookies for any purpose other than those listed in section 2 above.
                                </p>
                                <p className="color-black bottom--spacing">
                                    We use the following cookies:
                                </p>
                                <p className="color-black bottom--spacing">
                                    <ul className="diclaimer-list">
                                        <li>Strictly necessary cookies; These are cookies that are required for the operation of our website. They include, for example, cookies that enable you to log into secure areas of our website such as your Kuda UK account.</li>
                                        <li>Performance cookies: They allow us to recognise and count the number of visitors and to see how visitors move around our website when they are using it. This helps us to improve the way our website works, for example, by ensuring that users are finding what they are looking for easily.</li>
                                        <li>Functionality cookies; These are used to recognise you when you return to our website. This enables us to personalise our content for you, greet you by name and remember your preferences (for example, your choice of language or region).</li>
                                        <li>Analytics cookies: In order to keep our products and services easy to use and up-to-date, we use web analytics services to help us understand how people use our website. For example we can see which parts of our services are most popular, identify when errors occur and test different versions of a page or feature to see which one works best. These web analytics services may be designed and operated by other companies on our behalf. They do this using small invisible images known as “web beacons” or “tracking pixels” that may be included in the digital products and services on our website. These are used for example to count the number of times something has been seen. These web beacons are anonymous and do not contain or collect any information that identifies you.</li>
                                        <li>Targeting cookies; These cookies record your visit to our website, the pages you have visited and the links you have followed. We will use this information to make our website and the advertising displayed on it more relevant to your interests. We may also share this information with third parties for this purpose.</li>
                                        <li>By using our website, you agree that we can place the above types of cookies on your device.</li>
                                    </ul>
                                </p>
                            </div>

                            <h1 className="kuda-section--heading text-left text-xlbold color-primary title-bottom--spacing">
                                4.  Cookies we use
                            </h1>
                            <div className="text-left kuda-disclaimer--wrap">
                                <p className="color-black bottom--spacing">
                                    Below is a full list of the first party cookies we use together with a description of what they are used for, and what type of Cookie they are:
                                </p>
                                <div className="kuda-table bottom--spacing">
                                    <table border="1">
                                        <thead>
                                            <tr>
                                                <th>Cookie</th>
                                                <th>Type</th>
                                                <th>Description</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="text-bold">_gat_gid_ga</td>

                                                <td>Performance Cookies</td>
                                                <td>Used to distinguish users by Google Analytics – third-party analytics cookies: google analytics for statistical purposes. Type of data processed: IP addresses. To maximize the privacy of users / visitors, we use the “masking IP” feature, which imposes on the system the darkening of the last 8 digits of the IP address of the user / visitor before any type of processing is performed, making in fact the analyzed Purpose: to collect information, in aggregate form, on the number of users and on how they visit our site, to process statistics.</td>
                                            </tr>
                                            <tr>
                                                <td className="text-bold">ajs%3Acookies</td>

                                                <td>Functionality Cookie</td>
                                                <td>This cookie supports use, service performance, and functionality of websites. Cookies in this domain have lifespan of 1 year.</td>
                                            </tr>
                                            <tr>
                                                <td className="text-bold">ajs%3Atest</td>

                                                <td>Functionality Cookie</td>
                                                <td>This performance cookie counts visits and tracks other website traffic-related metrics. Cookies in this domain have lifespan of 1 year.</td>
                                            </tr>
                                            <tr>
                                                <td className="text-bold">
                                                    __cfruid
                                                </td>

                                                <td>Strictly necessary Cookie</td>
                                                <td>Cookie associated with sites using CloudFlare, used to identify trusted web traffic.</td>
                                            </tr>
                                            <tr>
                                                <td className="text-bold">__cfruid</td>
                                                <td>Strictly necessary Cookie</td>
                                                <td>Cookie associated with sites using CloudFlare, used to speed up page load times. According to CloudFlare it is used to override any security restrictions based on the IP address the visitor is coming from. It does not contain any user identification information.</td>
                                            </tr>
                                            <tr>
                                                <td className="text-bold">_gd#############</td>
                                                <td>Functionality Cookie</td>
                                                <td>This cookie contains no identifying information and is used for diagnostic purposes by Impact Radius, a service we use to track signup commissions for our affiliate program. We have categorized it under Functionality because it is necessary for the operation of our affiliate program.</td>
                                            </tr>
                                            <tr>
                                                <td className="text-bold">_fbp</td>
                                                <td>Targeting Cookie</td>
                                                <td>Used by Facebook to deliver a series of advertisement products such as real time bidding from third party advertisers</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <p className="color-black bottom--spacing">
                                    Below is a full list of the third party cookies we use together with a description of what they are used for, and whether they are a “persistent” or “session” cookie. Where a cookie is a third party cookie, please visit the providers’ website for more information.
                                </p>
                                <div className="kuda-table bottom--spacing">
                                    <table border="1">
                                        <thead>
                                            <tr>
                                                <th>Cookie</th>
                                                <th>Type</th>
                                                <th>Description</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="text-bold">Google Analytics and Google Tag Manager</td>

                                                <td>Persistent</td>
                                                <td>Used to track visitors and collect information about how visitors use our site. We use the information to compile reports and to help us improve the site. No personally identifiable information is stored. The cookies collect information in an anonymous form, including the number of visitors to the site, where visitors have come to the website from and the pages they visited.</td>
                                            </tr>
                                            <tr>
                                                <td className="text-bold">Hubspot</td>

                                                <td>Persistent</td>
                                                <td>Used to track visitors and enable us to provide a customised experience for each visitor. For example, if you provide your contact details in one of our contact forms, these details will be stored and used to identify future visits. We do this so we can optimise the content each visitor interacts with and make the browsing experience more personal.</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>

                            <h4 className="card-main--heading color-primary text-semi-bold mb-3">
                                1. Turning cookies off
                            </h4>
                            <div className="text-left kuda-disclaimer--wrap">
                                <p className="color-black bottom--spacing">
                                    In all modern browsers you may elect to reject cookies and/or enable do not track features, and we provide instructions on how to do this below. Please note however that by doing so parts of our website may no longer function as expected. For example, if you delete cookies any preferences they record will be lost. This includes for example where you have opted out from cookies, as this requires an opt-out cookie to be set.
                                </p>
                                <p className="color-black bottom--spacing">
                                    If your concerns are based around third party cookies, then we recommend you just reject those cookies, rather than all cookies so that we can still provide you with the functionality you expect.
                                </p>
                                <p className="color-black bottom--spacing">
                                    If you do wish to stop your browser from accepting cookies, see the following pages:
                                </p>
                                <p className="color-black bottom--spacing">
                                    <ul className="diclaimer-list">
                                        <li>Mozilla Firefox: {" "}
                                            <a
                                                href="https://support.mozilla.org/en-US/kb/enhanced-tracking-protection-firefox-desktop?redirectslug=enable-and-disable-cookies-website-preferences&redirectlocale=en-US"
                                                target="_blank"
                                                rel="nofollow noopener noreferrer"
                                                className="color-primary text-bold link-decoration cursor-pointer"
                                            >
                                                preferences
                                            </a>
                                        </li>
                                        <li>Google Chrome:  {" "}
                                            <a
                                                href="https://support.google.com/chrome/answer/95647?hl=en&p=cpn_cookies"
                                                target="_blank"
                                                rel="nofollow noopener noreferrer"
                                                className="color-primary text-bold link-decoration cursor-pointer"
                                            >
                                                cookies
                                            </a>
                                        </li>
                                        <li>Internet Explorer: {" "}
                                            <a
                                                href="https://support.microsoft.com/en-gb/windows/delete-and-manage-cookies-168dab11-0753-043d-7c16-ede5947fc64d"
                                                target="_blank"
                                                rel="nofollow noopener noreferrer"
                                                className="color-primary text-bold link-decoration cursor-pointer"
                                            >
                                                allow-cookies
                                            </a>
                                        </li>
                                        <li>Safari: {" "}
                                            <a
                                                href="https://support.apple.com/en-gb/guide/safari/sfri11471/mac"
                                                target="_blank"
                                                rel="nofollow noopener noreferrer"
                                                className="color-primary text-bold link-decoration cursor-pointer"
                                            >
                                                https://support.apple.com/kb/PH5042
                                            </a>
                                        </li>
                                    </ul>
                                </p>
                            </div>

                            <h4 className="card-main--heading color-primary text-semi-bold mb-3">
                                2. Tracking Pixels
                            </h4>
                            <div className="text-left kuda-disclaimer--wrap">
                                <p className="color-black bottom--spacing">
                                    We also use third party ‘tracking pixels’ in marketing emails sent to you to monitor and improve your email experience. These tracking pixels are provided by Hubspot.
                                </p>
                                <p className="color-black bottom--spacing">
                                    Tracking pixels have a similar function to cookies, but are a 1 pixel x 1 pixel clear image contained in the body of an email that contains unique identifiers that enable us to track which emails were opened, the device they were opened on, the email client and the browser used.
                                </p>
                                <p className="color-black bottom--spacing">
                                    We use this data to gauge the effectiveness of certain communications and the effectiveness of our marketing campaigns
                                </p>
                            </div>

                            <h4 className="card-main--heading color-primary text-semi-bold mb-3">
                                3. Withdraw Consent
                            </h4>
                            <div className="text-left kuda-disclaimer--wrap">
                                <p className="color-black bottom--spacing">
                                    You can withdraw your consent to receiving emails with tracking pixels at any time by unsubscribing from our mailing list, using the ‘unsubscribe’ link in the footer of the emails.
                                </p>
                            </div>

                            <h4 className="card-main--heading color-black text-semi-bold mb-3">
                                Contact us
                            </h4>
                            <div className="text-left kuda-disclaimer--wrap">
                                <p className="color-black bottom--spacing">
                                    If you have any questions about our cookies or this Cookies Policy, please contact us on: {" "}
                                    <a
                                        className="color-primary cursor-pointer link-decoration"
                                        href="mailto:dataprotectionuk@kuda.com"
                                    >
                                        dataprotectionuk@kuda.com
                                    </a>.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <CTAUk />
        </Fragment>
    )
}

export default CookieUkPolicy
